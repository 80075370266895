const ColumnData = [
  {
    "title": "[하현성 칼럼] ‘웰다잉 문화’ 정착을 위하여",
    "content": "각종 통계자료나 보도 자료를 통해 우리사회의 고령화 속도에 대한 많은 우려의 목소리가 있다. 통계청이 발표한 ‘세계와 한국의 인구현황 및 전망’에 따르면 한국의 고령인구 구성비는 2060년 40.1%까지 증가하게 되고 이에 따라 질병의 증가",
    "link": "http://www.swnews.kr"
  },
  {
    "title": "웰빙을 넘어 웰다잉을 이야기하다",
    "content": "갑작스럽게 사망하는 일부 경우를 제외한다면, 현대인의 대다수는 생의 마지막 순간에 암을 비롯한 만성질환을 앓다가 죽음을 맞이하게 된다. 의학의 발달로 평균 수명이 아무리 연장되어도, 누구나 언젠가는 회복 불가능한 건강 상태에 이를 수밖에 없다.",
    "link": "https://www.amc.seoul.kr/asan/depts/psy/K/bbsDetail.do?menuId=862&"
  },
  {
    "title": "일상에서 죽음을 말하자!",
    "content": "우리나라는 2025년이 되면 초고령사회가 된다. 노령 인구가 전 인구대비 20%가 넘게 된다. 고령화 사회에서 초고령화 사회로 진입하는데 25년, 세계에서 가장 빠른 속도로 진행되고 있다. 또한 2028을 기점으로 출생자 수보다 사망자 수가 많아진다.",
    "link": "http://goodnanum.or.kr/?p=4986"
  },
  {
    "title": "[시니어 칼럼]웰빙에서 웰다잉으로",
    "content": "2000년대 초 웰빙 열풍이 불었던 때가 있었다. 건강한 육체와 정신을 추구하는 라이프스타일이다. 그런데 최근 노인 인구가 급증하고 평균 기대수명이 늘어나면서 웰빙을 넘어 웰다잉에 관심을 갖는 사람들이 늘어나고 있다.",
    "link": "https://www.igoodnews.net/news/articleView.html?idxno=72462"
  },
  {
    "title": "아름다운 마무리,스스로 결정해야",
    "content": "끝이 좋으면 다 좋다. 셰익스피어 희곡의 제목처럼 삶의 마무리가 인생에서 중요하다. 왜 죽음을 이야기 하는가? 죽음은 그 의미를 되짚어보고 준비할 때 삶의 가치를 더 높일 수 있는 우리 인생의 한 단계이기 때문이다.",
    "link": "https://www.igoodnews.net/news/articleView.html?idxno=72462"
  },
  {
    "title": "웰다잉 시대와 죽음의 자기 결정권",
    "content": "철학자 하이데거는 “죽음은 현존재의 가장 고유한 가능성이다”라고 말했다. 그는 인간 존재의 모든 가능성이 끝나는 가장 극단적인 가능성이라는 의미에서, 죽음을 ‘불가능성의 가능성’이라고 표현했다. 인간은 하루하루 죽음으로 향하는 존재다.",
    "link": "https://ifs.or.kr/bbs/board.php?bo_table=YTT_board&wr_id=346"
  },
  {
    "title": "[하현성 칼럼] ‘웰다잉 문화’ 정착을 위하여",
    "content": "각종 통계자료나 보도 자료를 통해 우리사회의 고령화 속도에 대한 많은 우려의 목소리가 있다. 통계청이 발표한 ‘세계와 한국의 인구현황 및 전망’에 따르면 한국의 고령인구 구성비는 2060년 40.1%까지 증가하게 되고 이에 따라 질병의 증가",
    "link": "http://www.swnews.kr"
  },
  {
    "title": "웰빙을 넘어 웰다잉을 이야기하다",
    "content": "갑작스럽게 사망하는 일부 경우를 제외한다면, 현대인의 대다수는 생의 마지막 순간에 암을 비롯한 만성질환을 앓다가 죽음을 맞이하게 된다. 의학의 발달로 평균 수명이 아무리 연장되어도, 누구나 언젠가는 회복 불가능한 건강 상태에 이를 수밖에 없다.",
    "link": "https://www.amc.seoul.kr/asan/depts/psy/K/bbsDetail.do?menuId=862&"
  },
  {
    "title": "일상에서 죽음을 말하자!",
    "content": "우리나라는 2025년이 되면 초고령사회가 된다. 노령 인구가 전 인구대비 20%가 넘게 된다. 고령화 사회에서 초고령화 사회로 진입하는데 25년, 세계에서 가장 빠른 속도로 진행되고 있다. 또한 2028을 기점으로 출생자 수보다 사망자 수가 많아진다.",
    "link": "http://goodnanum.or.kr/?p=4986"
  },
  {
    "title": "[시니어 칼럼]웰빙에서 웰다잉으로",
    "content": "2000년대 초 웰빙 열풍이 불었던 때가 있었다. 건강한 육체와 정신을 추구하는 라이프스타일이다. 그런데 최근 노인 인구가 급증하고 평균 기대수명이 늘어나면서 웰빙을 넘어 웰다잉에 관심을 갖는 사람들이 늘어나고 있다. ",
    "link": "https://www.igoodnews.net/news/articleView.html?idxno=72462"
  },
  {
    "title": "인생의 아름다운 마무리,스스로 결정해야",
    "content": "끝이 좋으면 다 좋다. 셰익스피어 희곡의 제목처럼 삶의 마무리가 인생에서 중요하다. 왜 죽음을 이야기 하는가? 죽음은 그 의미를 되짚어보고 준비할 때 삶의 가치를 더 높일 수 있는 우리 인생의 한 단계이기 때문이다.",
    "link": "https://www.igoodnews.net/news/articleView.html?idxno=72462"
  },
  {
    "title": "웰다잉 시대와 죽음의 자기 결정권",
    "content": "철학자 하이데거는 “죽음은 현존재의 가장 고유한 가능성이다”라고 말했다. 그는 인간 존재의 모든 가능성이 끝나는 가장 극단적인 가능성이라는 의미에서, 죽음을 ‘불가능성의 가능성’이라고 표현했다. 인간은 하루하루 죽음으로 향하는 존재다. ",
    "link": "https://ifs.or.kr/bbs/board.php?bo_table=YTT_board&wr_id=346"
  },
  {
    "title": "[하현성 칼럼] ‘웰다잉 문화’ 정착을 위하여",
    "content": "각종 통계자료나 보도 자료를 통해 우리사회의 고령화 속도에 대한 많은 우려의 목소리가 있다. 통계청이 발표한 ‘세계와 한국의 인구현황 및 전망’에 따르면 한국의 고령인구 구성비는 2060년 40.1%까지 증가하게 되고 이에 따라 질병의 증가",
    "link": "http://www.swnews.kr"
  },
  {
    "title": "웰빙을 넘어 웰다잉을 이야기하다",
    "content": "갑작스럽게 사망하는 일부 경우를 제외한다면, 현대인의 대다수는 생의 마지막 순간에 암을 비롯한 만성질환을 앓다가 죽음을 맞이하게 된다. 의학의 발달로 평균 수명이 아무리 연장되어도, 누구나 언젠가는 회복 불가능한 건강 상태에 이를 수밖에 없다.",
    "link": "https://www.amc.seoul.kr/asan/depts/psy/K/bbsDetail.do?menuId=862&"
  },
  {
    "title": "일상에서 죽음을 말하자!",
    "content": "우리나라는 2025년이 되면 초고령사회가 된다. 노령 인구가 전 인구대비 20%가 넘게 된다. 고령화 사회에서 초고령화 사회로 진입하는데 25년, 세계에서 가장 빠른 속도로 진행되고 있다. 또한 2028을 기점으로 출생자 수보다 사망자 수가 많아진다.",
    "link": "http://goodnanum.or.kr/?p=4986"
  },
  {
    "title": "[시니어 칼럼]웰빙에서 웰다잉으로",
    "content": "2000년대 초 웰빙 열풍이 불었던 때가 있었다. 건강한 육체와 정신을 추구하는 라이프스타일이다. 그런데 최근 노인 인구가 급증하고 평균 기대수명이 늘어나면서 웰빙을 넘어 웰다잉에 관심을 갖는 사람들이 늘어나고 있다. ",
    "link": "https://www.igoodnews.net/news/articleView.html?idxno=72462"
  },
  {
    "title": "아름다운 마무리,스스로 결정해야",
    "content": "끝이 좋으면 다 좋다.’ 셰익스피어 희곡의 제목처럼 삶의 마무리가 인생에서 중요하다. 왜 죽음을 이야기 하는가? 죽음은 그 의미를 되짚어보고 준비할 때 삶의 가치를 더 높일 수 있는 우리 인생의 한 단계이기 때문이다. 인생의 모든 단계와 마찬가지로 죽음도 우리가",
    "link": "https://www.igoodnews.net/news/articleView.html?idxno=72462"
  },
  {
    "title": "웰다잉 시대와 죽음의 자기 결정권",
    "content": "철학자 하이데거는 “죽음은 현존재의 가장 고유한 가능성이다”라고 말했다. 그는 인간 존재의 모든 가능성이 끝나는 가장 극단적인 가능성이라는 의미에서, 죽음을 ‘불가능성의 가능성’이라고 표현했다. 인간은 하루하루 죽음으로 향하는 존재다. ",
    "link": "https://ifs.or.kr/bbs/board.php?bo_table=YTT_board&wr_id=346"
  },
  {
    "title": "[하현성 칼럼] ‘웰다잉 문화’ 정착을 위하여",
    "content": "각종 통계자료나 보도 자료를 통해 우리사회의 고령화 속도에 대한 많은 우려의 목소리가 있다. 통계청이 발표한 ‘세계와 한국의 인구현황 및 전망’에 따르면 한국의 고령인구 구성비는 2060년 40.1%까지 증가하게 되고 이에 따라 질병의 증가",
    "link": "http://www.swnews.kr"
  },
  {
    "title": "웰빙을 넘어 웰다잉을 이야기하다",
    "content": "갑작스럽게 사망하는 일부 경우를 제외한다면, 현대인의 대다수는 생의 마지막 순간에 암을 비롯한 만성질환을 앓다가 죽음을 맞이하게 된다. 의학의 발달로 평균 수명이 아무리 연장되어도, 누구나 언젠가는 회복 불가능한 건강 상태에 이를 수밖에 없다.",
    "link": "https://www.amc.seoul.kr/asan/depts/psy/K/bbsDetail.do?menuId=862&"
  },
  {
    "title": "일상에서 죽음을 말하자!",
    "content": "우리나라는 2025년이 되면 초고령사회가 된다. 노령 인구가 전 인구대비 20%가 넘게 된다. 고령화 사회에서 초고령화 사회로 진입하는데 25년, 세계에서 가장 빠른 속도로 진행되고 있다. 또한 2028을 기점으로 출생자 수보다 사망자 수가 많아진다. ",
    "link": "http://goodnanum.or.kr/?p=4986"
  },
  {
    "title": "[시니어 칼럼]웰빙에서 웰다잉으로",
    "content": "2000년대 초 웰빙 열풍이 불었던 때가 있었다. 건강한 육체와 정신을 추구하는 라이프스타일이다. 그런데 최근 노인 인구가 급증하고 평균 기대수명이 늘어나면서 웰빙을 넘어 웰다잉에 관심을 갖는 사람들이 늘어나고 있다. ",
    "link": "https://www.igoodnews.net/news/articleView.html?idxno=72462"
  },
  {
    "title": "아름다운 마무리,스스로 결정해야",
    "content": "끝이 좋으면 다 좋다.’ 셰익스피어 희곡의 제목처럼 삶의 마무리가 인생에서 중요하다. 왜 죽음을 이야기 하는가? 죽음은 그 의미를 되짚어보고 준비할 때 삶의 가치를 더 높일 수 있는 우리 인생의 한 단계이기 때문이다.",
    "link": "https://www.igoodnews.net/news/articleView.html?idxno=72462"
  },
  {
    "title": "웰다잉 시대와 죽음의 자기 결정권",
    "content": "철학자 하이데거는 “죽음은 현존재의 가장 고유한 가능성이다”라고 말했다. 그는 인간 존재의 모든 가능성이 끝나는 가장 극단적인 가능성이라는 의미에서, 죽음을 ‘불가능성의 가능성’이라고 표현했다. 인간은 하루하루 죽음으로 향하는 존재다. ",
    "link": "https://ifs.or.kr/bbs/board.php?bo_table=YTT_board&wr_id=346"
  }
]

export default ColumnData