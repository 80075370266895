/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 grandMother.gltf 
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Grandmother(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/grandmother.gltf')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    const action = actions['Cube.050Action']
    action.play()
  },[])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Cube050" position={[10.387, 0.387, 1.84]} rotation={[0, -0.866, -0.209]}>
          <mesh name="Cube148" geometry={nodes.Cube148.geometry} material={materials.chair} />
          <mesh name="Cube148_1" geometry={nodes.Cube148_1.geometry} material={materials['Material.003']} />
          <mesh name="Cube148_2" geometry={nodes.Cube148_2.geometry} material={materials['Material.014']} />
          <mesh name="Cube148_3" geometry={nodes.Cube148_3.geometry} material={materials['chair.001']} />
          <mesh name="Cube023" geometry={nodes.Cube023.geometry} material={materials.grandmother} position={[-1.072, 3.325, 0.493]} rotation={[3.141, -0.005, -2.989]} scale={[0.79, 1.017, 0.994]} />
          <mesh name="Cube074" geometry={nodes.Cube074.geometry} material={materials.grandmother} position={[-0.727, 5.311, 0.468]} rotation={[3.141, -0.005, -3.067]} scale={[1.328, 1.005, 1.33]} />
          <mesh name="Cube183" geometry={nodes.Cube183.geometry} material={materials.grandmother} position={[-1.771, 3.503, 0.483]} rotation={[0.009, -0.041, 0.006]} scale={0.325} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/grandmother.gltf')
