import * as React from "react";
const SvgGoogle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <rect
      width={35.5}
      height={35.5}
      x={0.25}
      y={0.25}
      fill="#fff"
      stroke="#B8B8C7"
      strokeWidth={0.5}
      rx={17.75}
    />
    <g clipPath="url(#google_svg__a)">
      <path
        fill="#4285F4"
        d="M26.815 18.72c0-.767-.062-1.328-.198-1.908H17.83v3.464h5.158c-.104.86-.665 2.157-1.913 3.029l-.018.116 2.779 2.147.192.019c1.768-1.629 2.787-4.025 2.787-6.867"
      />
      <path
        fill="#34A853"
        d="M17.83 27.848c2.527 0 4.648-.83 6.198-2.261l-2.954-2.282c-.79.55-1.85.933-3.244.933-2.475 0-4.576-1.628-5.325-3.88l-.11.01-2.888 2.23-.038.105a9.36 9.36 0 0 0 8.36 5.145"
      />
      <path
        fill="#FBBC05"
        d="M12.506 20.36a5.7 5.7 0 0 1-.312-1.847c0-.643.114-1.266.301-1.847l-.005-.123-2.925-2.266-.096.045a9.3 9.3 0 0 0-.998 4.19c0 1.505.364 2.926.998 4.191z"
      />
      <path
        fill="#EB4335"
        d="M17.83 12.787c1.758 0 2.943.757 3.62 1.39l2.64-2.572c-1.622-1.505-3.733-2.428-6.26-2.428a9.36 9.36 0 0 0-8.36 5.145l3.025 2.344c.76-2.25 2.86-3.88 5.335-3.88"
      />
    </g>
    <defs>
      <clipPath id="google_svg__a">
        <path fill="#fff" d="M8.47 9.177h18.354v18.735H8.47z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGoogle;
