/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 5_5_Diary.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Diary(props) {
  const { nodes, materials } = useGLTF('/models/5_5_Diary.gltf')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.book.geometry} material={materials['bigbook.003']} position={[-3.602, 0.471, -7.389]} rotation={[Math.PI / 2, 0, -0.365]} scale={[0.168, 0.2, 0.208]} />
    </group>
  )
}

useGLTF.preload('/models/5_5_Diary.gltf')
