/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 5_7_Desktop.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Desktop(props) {
  const { nodes, materials } = useGLTF('/models/5_7_Desktop.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[12.167, 7.962, -3.558]}>
        <mesh geometry={nodes.Cube078.geometry} material={materials['Material.087']} />
        <mesh geometry={nodes.Cube078_1.geometry} material={materials['Material.088']} />
      </group>
    </group>
  )
}

useGLTF.preload('/models/5_7_Desktop.gltf')
