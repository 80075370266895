/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 ending.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'

export default function Ending(props) {
  const { nodes, materials } = useGLTF('/models/ending.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[1.515, 104.629, -182.686]} rotation={[0, 0.603, 0]} scale={[10.66, 8.193, 15.586]}>
        <mesh geometry={nodes.Icosphere012.geometry} material={materials['treegreen2.001']} />
        <mesh geometry={nodes.Icosphere012_1.geometry} material={materials['treewood2.001']} />
      </group>
      <group position={[-107.9, 22.933, -144.118]} rotation={[2.03, 1.032, -2.31]} scale={[1.847, 2.188, 6.755]}>
        <mesh geometry={nodes.Cube091.geometry} material={materials['treewood2.001']} />
        <mesh geometry={nodes.Cube091_1.geometry} material={materials['treegreen3.001']} />
      </group>
      <group position={[49.587, 46.19, -154.591]} rotation={[2.973, 1.198, -2.997]} scale={[1.93, 2.67, 1.93]}>
        <mesh geometry={nodes.Cube093_1.geometry} material={materials['treewood3.001']} />
        <mesh geometry={nodes.Cube093_2.geometry} material={materials['treegreen3.001']} />
      </group>
      <group position={[-19.094, 41.696, -162.735]} rotation={[0.908, 1.487, -0.801]} scale={[1.231, 2.632, 1.908]}>
        <mesh geometry={nodes.Cube095.geometry} material={materials['treewood2.001']} />
        <mesh geometry={nodes.Cube095_1.geometry} material={materials['treegreen1.001']} />
      </group>
      <mesh geometry={nodes.trees023.geometry} material={materials['minitree2.002']} position={[40.598, 84.73, -84.462]} rotation={[0, 0.336, 0]} scale={[5.771, 7.914, 5.771]} />
      <group position={[111.559, 21.3, -31.429]} rotation={[0, -1.204, 0]} scale={10.814}>
        <mesh geometry={nodes.Cube100.geometry} material={materials['treewood1.002']} />
        <mesh geometry={nodes.Cube100_1.geometry} material={materials['treegreen1.002']} />
      </group>
      <group position={[-276.569, 62.637, 66.91]} rotation={[2.846, 1.342, -2.748]} scale={[3.226, 4.463, 3.226]}>
        <mesh geometry={nodes.Cube106_1.geometry} material={materials['treewood2.002']} />
        <mesh geometry={nodes.Cube106_2.geometry} material={materials['treegreen1.002']} />
      </group>
      <group position={[107.533, 62.637, 66.91]} rotation={[2.846, 1.342, -2.748]} scale={[3.226, 4.463, 3.226]}>
        <mesh geometry={nodes.Cube113.geometry} material={materials['treewood2.003']} />
        <mesh geometry={nodes.Cube113_1.geometry} material={materials['treegreen1.003']} />
      </group>
      <group position={[48.036, 39.444, 123.046]} rotation={[0.041, -0.207, -0.012]} scale={[2.144, 2.966, 2.144]}>
        <mesh geometry={nodes.Cube114.geometry} material={materials['treewood1.003']} />
        <mesh geometry={nodes.Cube114_1.geometry} material={materials['treegreen1.003']} />
      </group>
      <group position={[30.041, 11.975, 61.444]} rotation={[0.607, 0.633, -0.606]} scale={[1.213, 1.049, 4.131]}>
        <mesh geometry={nodes.Cube116.geometry} material={materials['treewood2.003']} />
        <mesh geometry={nodes.Cube116_1.geometry} material={materials['treegreen3.003']} />
      </group>
      <group position={[6.78, 109.816, 26.742]} rotation={[0, 0.454, 0]} scale={[12.754, 9.803, 18.648]}>
        <mesh geometry={nodes.Icosphere005_1.geometry} material={materials.treeorange1} />
        <mesh geometry={nodes.Icosphere005_2.geometry} material={materials.treewood3} />
      </group>
      <mesh geometry={nodes.trees001.geometry} material={materials['minitree.014']} position={[-137.106, 59.953, -159.556]} rotation={[0, 1.539, 0]} scale={[4.21, 4.8, 5.635]} />
      <mesh geometry={nodes.Cube.geometry} material={materials.campfire2} position={[-67.93, 47.051, -47.12]} rotation={[0, -0.541, 0]} scale={[24.449, 24.449, 34.145]} />
      <mesh geometry={nodes.Camp_fire001.geometry} material={materials.Material} position={[-136.806, 24.137, -77.858]} scale={10.518} />
      <group position={[132.609, 21.447, -194.137]} rotation={[0, -1.204, 0]} scale={5.656}>
        <mesh geometry={nodes.Cube030.geometry} material={materials.treewood1} />
        <mesh geometry={nodes.Cube030_1.geometry} material={materials.treegreen1} />
      </group>
      <group position={[149.547, 18.386, -134.773]} rotation={[Math.PI, -0.452, Math.PI]} scale={[9.92, 20.464, 12.558]}>
        <mesh geometry={nodes.Cylinder001_1.geometry} material={materials.treewood1} />
        <mesh geometry={nodes.Cylinder001_2.geometry} material={materials.treegreen2} />
      </group>
      <group position={[146.018, 42.638, 23.133]} rotation={[0, -0.853, 0]} scale={[2.575, 3.563, 2.575]}>
        <mesh geometry={nodes.Cube046.geometry} material={materials.treewood2} />
        <mesh geometry={nodes.Cube046_1.geometry} material={materials.treegreen3} />
      </group>
      <group position={[125.4, 34.579, 8.039]} rotation={[2.838, -1.323, 2.871]} scale={[1.615, 2.234, 1.615]}>
        <mesh geometry={nodes.Cube047.geometry} material={materials.wood1} />
        <mesh geometry={nodes.Cube047_1.geometry} material={materials.treered1} />
      </group>
      <group position={[55.508, 20.97, -266.28]} rotation={[2.171, 0.98, -2.476]} scale={[2.193, 1.896, 7.467]}>
        <mesh geometry={nodes.Cube060.geometry} material={materials.treewood2} />
        <mesh geometry={nodes.Cube060_1.geometry} material={materials.treegreen1} />
      </group>
      <group position={[-6.129, 43.452, 85.828]} rotation={[3.082, -0.277, 3.073]} scale={[1.615, 2.234, 1.615]}>
        <mesh geometry={nodes.Cube061.geometry} material={materials.treewood2} />
        <mesh geometry={nodes.Cube061_1.geometry} material={materials.treegreen1} />
      </group>
      <group position={[29.839, 48.06, -301.393]} scale={[2.575, 3.563, 2.575]}>
        <mesh geometry={nodes.Cube062.geometry} material={materials['Material.079']} />
        <mesh geometry={nodes.Cube062_1.geometry} material={materials.treegreen1} />
      </group>
      <group position={[-50.971, 140.826, -246.311]} rotation={[0, 0.603, 0]} scale={[15.978, 12.28, 23.361]}>
        <mesh geometry={nodes.Icosphere035_1.geometry} material={materials.treegreen2} />
        <mesh geometry={nodes.Icosphere035_2.geometry} material={materials.treewood2} />
      </group>
      <group position={[-139.766, 28.354, 12.027]} rotation={[0.414, -0.388, -0.003]} scale={[1.235, 1.067, 4.205]}>
        <mesh geometry={nodes.Cube074.geometry} material={materials.treewood2} />
        <mesh geometry={nodes.Cube074_1.geometry} material={materials.treegreen2} />
      </group>
      <group position={[82.378, 16.194, -148.443]} rotation={[2.634, -0.699, 2.63]} scale={[1.33, 1.15, 4.529]}>
        <mesh geometry={nodes.Cube075_1.geometry} material={materials.treewood3} />
        <mesh geometry={nodes.Cube075_2.geometry} material={materials.treered1} />
      </group>
      <group position={[-234.171, 31.292, -252.228]} scale={3.668}>
        <mesh geometry={nodes.Cube078_1.geometry} material={materials.treewood3} />
        <mesh geometry={nodes.Cube078_2.geometry} material={materials.treegreen1} />
      </group>
      <group position={[-68.894, 62.637, -191.944]} rotation={[2.846, 1.342, -2.748]} scale={[3.226, 4.463, 3.226]}>
        <mesh geometry={nodes.Cube085_1.geometry} material={materials.treewood2} />
        <mesh geometry={nodes.Cube085_2.geometry} material={materials.treegreen1} />
      </group>
      <group position={[-273.582, 54.182, -172.62]} rotation={[0.041, -0.207, -0.012]} scale={[2.144, 2.966, 2.144]}>
        <mesh geometry={nodes.Cube086.geometry} material={materials.treewood1} />
        <mesh geometry={nodes.Cube086_1.geometry} material={materials.treegreen1} />
      </group>
      <group position={[-157.047, 59.854, -305.861]} rotation={[0, 0.149, 0]} scale={[3.226, 4.463, 3.226]}>
        <mesh geometry={nodes.Cube096_1.geometry} material={materials.treewood2} />
        <mesh geometry={nodes.Cube096_2.geometry} material={materials.treegreen2} />
      </group>
      <mesh geometry={nodes.trees014.geometry} material={materials.minitree} position={[-123.942, 44.061, -258.825]} rotation={[0, 0.336, 0]} scale={[1.579, 2.165, 1.579]} />
      <group position={[-175.769, 59.587, -23.073]} rotation={[0.359, 1.175, -0.303]} scale={[3.226, 4.463, 3.226]}>
        <mesh geometry={nodes.Cube098_1.geometry} material={materials.treewood4} />
        <mesh geometry={nodes.Cube098_2.geometry} material={materials.treegreen3} />
      </group>
      <group position={[59.863, 44.953, 89.078]} rotation={[2.973, 1.198, -2.997]} scale={[2.629, 3.637, 2.629]}>
        <mesh geometry={nodes.Cube099.geometry} material={materials.treewood3} />
        <mesh geometry={nodes.Cube099_1.geometry} material={materials.treegreen2} />
      </group>
      <group position={[86.795, 63.056, 39.063]} rotation={[0, 0.149, 0]} scale={[3.226, 4.463, 3.226]}>
        <mesh geometry={nodes.Cube101.geometry} material={materials.treewood4} />
        <mesh geometry={nodes.Cube101_1.geometry} material={materials.treegreen2} />
      </group>
      <mesh geometry={nodes.SDFSDF.geometry} material={materials.SDFSDF} position={[-229.988, 108.626, 148.901]} rotation={[Math.PI / 2, 0, Math.PI]} />
      <mesh geometry={nodes.Plane.geometry} material={materials.ground} position={[-96.963, 18.7, -57.867]} rotation={[0, 0, -Math.PI]} scale={337.152} />
      <mesh geometry={nodes.trees017.geometry} material={materials['minitree.001']} position={[-141.002, 59.124, -233.918]} rotation={[0, 0.336, 0]} scale={[3.471, 4.76, 3.471]} />
      <mesh geometry={nodes.trees009.geometry} material={materials.minitree2} position={[-176.447, 53.798, 3.582]} rotation={[0, -0.007, 0]} scale={[2.997, 3.592, 2.946]} />
      <mesh geometry={nodes.trees011.geometry} material={materials['minitree.004']} position={[0.414, 44.116, -237.429]} rotation={[0, 1.539, 0]} scale={[2.326, 3.189, 2.326]} />
      <mesh geometry={nodes.trees008.geometry} material={materials.minitree2} position={[103.367, 42.294, -229.89]} rotation={[0, 0.336, 0]} scale={[1.993, 2.734, 1.993]} />
      <mesh geometry={nodes.trees007.geometry} material={materials['minitree2.001']} position={[230.964, 45.659, -82.898]} rotation={[-3.121, 0.238, 3.056]} scale={[2.628, 3.604, 2.628]} />
      <mesh geometry={nodes.trees004.geometry} material={materials['minitree.006']} position={[131.373, 52.164, -125.335]} rotation={[0, 1.539, 0]} scale={[3.257, 4.467, 3.257]} />
      <mesh geometry={nodes.trees003.geometry} material={materials['minitree.007']} position={[-58.196, 47.04, -110.98]} rotation={[0, 1.539, 0]} scale={[2.964, 3.38, 3.968]} />
      <group position={[-2.05, 20.97, -23.135]} rotation={[-Math.PI, 0.226, -Math.PI]} scale={[3.947, 8.141, 4.996]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials['Material.079']} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.treegreen1} />
      </group>
      <group position={[42.669, 18.745, -0.899]} scale={3.668}>
        <mesh geometry={nodes.Cube043.geometry} material={materials.treewood3} />
        <mesh geometry={nodes.Cube043_1.geometry} material={materials.treegreen2} />
      </group>
      <mesh geometry={nodes.Cube002.geometry} material={materials.camp1} position={[-131.884, 34.601, -53.287]} scale={[5.586, 1.258, 5.586]} />
      <mesh geometry={nodes.Cube007.geometry} material={materials.camp1} position={[-117.032, 27.328, -102.938]} rotation={[0, -0.178, 0]} scale={[18.333, 3.318, 3.318]} />
      <group position={[-75.831, 21.3, 50.883]} rotation={[0, -1.204, 0]} scale={10.814}>
        <mesh geometry={nodes.Cube013_1.geometry} material={materials['treewood1.004']} />
        <mesh geometry={nodes.Cube013_2.geometry} material={materials['treegreen1.004']} />
      </group>
      <group position={[-27.143, 22.933, 32.86]} rotation={[2.03, 1.032, -2.31]} scale={[1.847, 2.188, 6.755]}>
        <mesh geometry={nodes.Cube014_1.geometry} material={materials['treewood2.004']} />
        <mesh geometry={nodes.Cube014_2.geometry} material={materials['treegreen3.004']} />
      </group>
      <mesh geometry={nodes.Cube012.geometry} material={materials.campfire2} position={[-100.437, 30.951, -80.701]} rotation={[-Math.PI, 1.298, -Math.PI]} scale={[4.96, 1.118, 4.96]} />
      <mesh geometry={nodes.Cube003.geometry} material={materials['camp1.001']} position={[-157.304, 27.328, -74.314]} rotation={[0.075, 1.386, -0.07]} scale={[18.333, 3.318, 3.318]} />
      <mesh geometry={nodes.Plane003.geometry} material={materials['Material.004']} position={[212.879, 187.547, 96.154]} rotation={[Math.PI / 2, 0, -1.235]} scale={[1500.754, 20.05, 600.236]} />
    </group>
  )
}

useGLTF.preload('/models/ending.gltf')
