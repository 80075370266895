/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 5_4_Phone.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Phone(props) {
  const { nodes, materials } = useGLTF('/models/5_4_Phone.gltf')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube019.geometry} material={materials['Material.089']} position={[-4.192, 5.386, 11.207]} rotation={[-Math.PI, 0.303, -Math.PI]} scale={[-0.499, -0.067, -0.77]} />
    </group>
  )
}

useGLTF.preload('/models/5_4_Phone.gltf')
