/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 5_6_Will.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Will(props) {
  const { nodes, materials } = useGLTF('/models/5_6_Will.gltf')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Will.geometry} material={materials['Material.086']} position={[9.476, 5.049, -4.41]} rotation={[0, 1.436, 0]} scale={[1.029, 0.004, 1.156]}>
        <mesh geometry={nodes.Cube030.geometry} material={nodes.Cube030.material} position={[0, -3.123, 0]} rotation={[0, 0.233, 0]} scale={[0.988, 1, 1.023]} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/models/5_6_Will.gltf')
