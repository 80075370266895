export const MessageArr = [
  `하숙집을 관리하는 주인 할머니의 방 입니다. \n화살표를 눌러 할머니와의 대화를 이어나가보세요!`,
  '반가워요, 나는 마리앤이에요. 이 하숙집의 주인이랍니다.',
  '하숙집이 정말 아늑하다고요? 고마워요. \n 이곳에서 따뜻하고 즐거운 시간을 보낼 수 있을 거예요.',
  '나는 평범한 삶을 살았어요. 다정한 남자와 결혼하고, 아이를 낳고, 가정에 충실한 그런 삶... \n 어느 날 문득 거울을 보다가 생각했어요. 예전의 그 아름답던 마리앤은 어디있지?',
  '나는 늦게라도 꿈을 향해 나아가기 위해 내가 하고 싶은 일들을 생각했어요.',
  '이 우쿠렐레를 봐요, 나는 음악을 사랑했지만 사는 동안 한번도 악기를 배워보지 못했어요. \n 지금에 와서야 비로소 우쿠렐레를 배우게 되었답니다. \n 처음에는 어려워서 포기하려고도 했어요. 그런데 꾸준한 노력끝에 마침내 곡 하나를 완벽하게 연주할 수 있게 되었죠! ',
  '또, 대학 졸업장도 늦게 얻었어요. 검정고시를 치고, 대학에 다니며 꿈을 이루었어요. 나이는 숫자일 뿐, 꿈을 향한 열정이 중요하다고 생각해요.',
  '목적과 목표를 가진 의미있는 삶을 살아가는 게 무엇보다 중요해요. \n 순간순간의 나의 노력과 시간을 가치있게 쓴다면, 삶을 대하는 태도는 매우 의미있어질 것이에요.'
]
