import * as React from "react";
const SvgHidePwd = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#4D4D4D"
      d="M8 2.667c-3.636 0-6.742 2.261-8 5.454 1.258 3.193 4.364 5.455 8 5.455s6.742-2.262 8-5.455c-1.258-3.193-4.364-5.454-8-5.454m0 9.09a3.64 3.64 0 0 1-3.636-3.636A3.64 3.64 0 0 1 8 4.485a3.64 3.64 0 0 1 3.636 3.636A3.64 3.64 0 0 1 8 11.757M8 5.94a2.18 2.18 0 0 0-2.182 2.182c0 1.207.975 2.182 2.182 2.182a2.18 2.18 0 0 0 2.182-2.182A2.18 2.18 0 0 0 8 5.939"
    />
  </svg>
);
export default SvgHidePwd;
