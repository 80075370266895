/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 main_ground.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'

export default function Main_Ground(props) {
  const { nodes, materials } = useGLTF('/models/main_ground.gltf')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.trees001.geometry} material={materials['Material.001']} position={[-64.22, 30.389, 11.555]} scale={[0.456, 0.625, 0.456]} />
      <mesh geometry={nodes.ground.geometry} material={materials['Material.007']} position={[-102.02, 25.842, -94.977]} rotation={[0, -1.395, 0]} scale={[15.341, 11.353, 9.82]} />
      <mesh geometry={nodes.sign.geometry} material={materials['Material.079']} position={[119.443, 24.847, -16.963]} rotation={[Math.PI, -0.654, Math.PI]} scale={[0.716, 1, 1]} />
      <mesh geometry={nodes.Icosphere009.geometry} material={materials.treegreen1} position={[59.383, 33.592, -35.516]} rotation={[-0.033, -0.629, -0.122]} scale={[3.14, 2.787, 3.14]} />
      <mesh geometry={nodes.Icosphere010.geometry} material={materials.treegreen1} position={[56.809, 34.065, -38.358]} rotation={[-0.16, -1.206, 0.205]} scale={[4.263, 3.785, 4.263]} />
      <mesh geometry={nodes.Icosphere011.geometry} material={materials.treegreen1} position={[57.378, 31.733, -36.878]} rotation={[0, -1.204, 0]} scale={[4.979, 4.42, 4.979]} />
      <mesh geometry={nodes.tree4.geometry} material={materials.treewood1} position={[57.378, 23.203, -36.878]} rotation={[0, -1.204, 0]} scale={0.932} />
      <group position={[92.043, 33.532, 1.892]} rotation={[0, -0.035, 0.554]} scale={[5.241, 4.496, 6.99]}>
        <mesh geometry={nodes.Icosphere014_1.geometry} material={materials.treegreen1} />
        <mesh geometry={nodes.Icosphere014_2.geometry} material={materials.treewood3} />
      </group>
      <group position={[76.769, 22.059, 9.278]} rotation={[2.646, 0.258, 3.062]} scale={[0.369, 0.319, 1.256]}>
        <mesh geometry={nodes.Cube045.geometry} material={materials.treewood1} />
        <mesh geometry={nodes.Cube045_1.geometry} material={materials.treegreen2} />
      </group>
      <group position={[-33.552, 55.259, -2.721]} rotation={[0, -0.4, 0]} scale={[4.816, 3.701, 7.041]}>
        <mesh geometry={nodes.Icosphere018_1.geometry} material={materials.treegreen3} />
        <mesh geometry={nodes.Icosphere018_2.geometry} material={materials.treewood2} />
      </group>
      <group position={[-26.725, 28.297, -9.928]} rotation={[2.838, -1.323, 2.871]} scale={[0.61, 0.843, 0.61]}>
        <mesh geometry={nodes.Cube047.geometry} material={materials.wood1} />
        <mesh geometry={nodes.Cube047_1.geometry} material={materials.treered1} />
      </group>
      <mesh geometry={nodes.Icosphere024.geometry} material={materials.treegreen1} position={[19.331, 19.705, -37.741]} rotation={[-Math.PI, 0.465, -Math.PI]} scale={[5.28, 3.153, 7.72]} />
      <mesh geometry={nodes.Icosphere025.geometry} material={materials.treegreen1} position={[22.184, 21.61, -38.515]} rotation={[-Math.PI, 0.465, -Math.PI]} scale={[5.818, 5.926, 6.875]} />
      <mesh geometry={nodes.Icosphere026.geometry} material={materials.treegreen1} position={[19.331, 21.61, -37.741]} rotation={[-Math.PI, 0.535, -Math.PI]} scale={[2.217, 2.004, 4.095]} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials.treewood2} position={[19.331, 21.61, -37.741]} rotation={[-Math.PI, 0.465, -Math.PI]} scale={[1.196, 2.467, 1.514]} />
      <mesh geometry={nodes.Cube079.geometry} material={materials.treewood2} position={[19.331, 21.61, -37.741]} rotation={[2.171, 0.98, -2.476]} scale={[0.466, 0.403, 1.588]} />
      <mesh geometry={nodes.Icosphere027.geometry} material={materials.treegreen1} position={[-3.792, 43.297, -40.236]} rotation={[3.042, -0.729, 3.054]} scale={[3.02, 2.321, 4.415]} />
      <mesh geometry={nodes.Icosphere028.geometry} material={materials.treegreen1} position={[-4.069, 41.687, -38.969]} rotation={[3.042, -0.729, 3.054]} scale={[4.351, 2.321, 6.362]} />
      <mesh geometry={nodes.Icosphere029.geometry} material={materials.treegreen1} position={[-3.839, 38.192, -39.689]} rotation={[3.042, -0.729, 3.054]} scale={[5.394, 4.104, 7.886]} />
      <mesh geometry={nodes.Cube080.geometry} material={materials.treewood2} position={[-3.941, 30.098, -39.781]} rotation={[3.082, -0.277, 3.073]} scale={[0.61, 0.843, 0.61]} />
      <mesh geometry={nodes.Icosphere030.geometry} material={materials.treegreen1} position={[7.322, 55.545, -49.419]} rotation={[0, 0.454, 0]} scale={[4.816, 3.701, 7.041]} />
      <mesh geometry={nodes.Icosphere031.geometry} material={materials.treegreen1} position={[8.409, 53.351, -51.09]} rotation={[0, 0.454, 0]} scale={[6.939, 3.701, 10.145]} />
      <mesh geometry={nodes.Icosphere032.geometry} material={materials.treegreen1} position={[8.409, 47.762, -51.09]} rotation={[0, 0.454, 0]} scale={[8.601, 6.544, 12.575]} />
      <mesh geometry={nodes.Cube081.geometry} material={materials['Material.079']} position={[9.639, 31.838, -50.998]} scale={[0.972, 1.345, 0.972]} />
      <mesh geometry={nodes.Icosphere035.geometry} material={materials.treegreen2} position={[-52.506, 66.862, -71.796]} rotation={[0, 0.603, 0]} scale={[6.033, 4.637, 8.82]} />
      <mesh geometry={nodes.Cube082.geometry} material={materials.treewood2} position={[-49.93, 37.163, -74.185]} rotation={[0, 0.149, 0]} scale={[1.218, 1.685, 1.218]} />
      <group position={[-91.778, 24.397, -108.838]} rotation={[-0.106, 0.233, 0]} scale={[2.217, 2.004, 4.095]}>
        <mesh geometry={nodes.Icosphere036_1.geometry} material={materials.treegreen2} />
        <mesh geometry={nodes.Icosphere036_2.geometry} material={materials.treewood2} />
      </group>
      <mesh geometry={nodes.Icosphere038.geometry} material={materials.treered1} position={[-19.875, 23.794, -44.181]} rotation={[-0.102, 0.14, -0.017]} scale={[3.639, 3.707, 4.301]} />
      <mesh geometry={nodes.Cube084.geometry} material={materials.treewood3} position={[-20.649, 23.899, -44.086]} rotation={[0.448, -0.537, 0.073]} scale={[0.292, 0.252, 0.993]} />
      <group position={[-92.396, 42.131, -58.582]} rotation={[-0.057, -0.007, 0.354]} scale={[6.336, 5.626, 6.336]}>
        <mesh geometry={nodes.Icosphere041_1.geometry} material={materials.treegreen1} />
        <mesh geometry={nodes.Icosphere041_2.geometry} material={materials.treewood3} />
      </group>
      <mesh geometry={nodes.Icosphere044.geometry} material={materials.treegreen3} position={[-36.019, 39.136, -54.821]} rotation={[-0.058, 0.209, 0.367]} scale={[4.546, 5.174, 4.314]} />
      <mesh geometry={nodes.Cube086.geometry} material={materials.treewood3} position={[-34.455, 24.552, -55.165]} rotation={[0, 0.216, 0]} scale={0.942} />
      <mesh geometry={nodes.Icosphere046.geometry} material={materials.treegreen1} position={[-104.304, 66.375, -180.256]} rotation={[3.12, 0.901, -3.001]} scale={[6.033, 4.637, 8.82]} />
      <mesh geometry={nodes.Cube087.geometry} material={materials.treewood2} position={[-106.048, 37.341, -185.283]} rotation={[2.846, 1.342, -2.748]} scale={[1.218, 1.685, 1.218]} />
      <mesh geometry={nodes.Icosphere050.geometry} material={materials.treegreen1} position={[-104.735, 48.81, -164.946]} rotation={[0.046, 0.246, -0.012]} scale={[5.777, 3.081, 8.446]} />
      <mesh geometry={nodes.Cube088.geometry} material={materials.treewood1} position={[-104.921, 34.149, -164.088]} rotation={[0.041, -0.207, -0.012]} scale={[0.809, 1.12, 0.809]} />
      <group position={[-92.849, 35.89, -170.677]} scale={[1.08, 1.481, 1.08]}>
        <mesh geometry={nodes.Sphere015.geometry} material={materials['tree3.520']} />
        <mesh geometry={nodes.Sphere015_1.geometry} material={materials['wood1.670']} />
      </group>
      <group position={[-122.785, 31.545, -109.585]} rotation={[2.973, 1.198, -2.997]} scale={[0.993, 1.373, 0.993]}>
        <mesh geometry={nodes.Cube092_1.geometry} material={materials.treewood3} />
        <mesh geometry={nodes.Cube092_2.geometry} material={materials.treegreen3} />
      </group>
      <mesh geometry={nodes.Icosphere051.geometry} material={materials.treegreen2} position={[-62.463, 56.24, -146.553]} rotation={[0, 0.603, 0]} scale={[10.775, 8.198, 15.754]} />
      <mesh geometry={nodes.Cube092.geometry} material={materials.treewood2} position={[-60.922, 36.291, -146.668]} rotation={[0, 0.149, 0]} scale={[1.218, 1.685, 1.218]} />
      <mesh geometry={nodes.trees014.geometry} material={materials.minitree} position={[-48.422, 30.328, -61.073]} rotation={[0, 0.336, 0]} scale={[0.596, 0.818, 0.596]} />
      <mesh geometry={nodes.Icosphere055.geometry} material={materials.treegreen3} position={[-50.435, 24.963, -173.542]} rotation={[0, 1.402, 0]} scale={[2.178, 1.969, 4.023]} />
      <mesh geometry={nodes.Cube090.geometry} material={materials.treewood2} position={[-50.435, 24.963, -173.542]} rotation={[0.607, 0.633, -0.606]} scale={[0.458, 0.396, 1.56]} />
      <group position={[-61.041, 37.732, -156.592]} rotation={[Math.PI, -0.503, Math.PI]} scale={[1.31, 1.797, 1.31]}>
        <mesh geometry={nodes.Sphere017.geometry} material={materials['tree3.604']} />
        <mesh geometry={nodes.Sphere017_1.geometry} material={materials['wood1.778']} />
      </group>
      <mesh geometry={nodes.Icosphere059.geometry} material={materials.treegreen3} position={[-66.366, 57.838, -211.496]} rotation={[2.221, 1.434, -2.138]} scale={[10.775, 8.198, 15.754]} />
      <mesh geometry={nodes.Cube093.geometry} material={materials.treewood4} position={[-67.99, 36.19, -214.435]} rotation={[0.359, 1.175, -0.303]} scale={[1.218, 1.685, 1.218]} />
      <group position={[-24.469, 35.054, -249.346]} scale={[1.08, 1.481, 1.08]}>
        <mesh geometry={nodes.Sphere018.geometry} material={materials['tree3.646']} />
        <mesh geometry={nodes.Sphere018_1.geometry} material={materials['wood1.832']} />
      </group>
      <mesh geometry={nodes.Icosphere061.geometry} material={materials.treegreen2} position={[21.862, 43.022, -197.179]} rotation={[3.059, 0.746, -3.07]} scale={[8.782, 6.682, 12.839]} />
      <mesh geometry={nodes.Cube094.geometry} material={materials.treewood3} position={[20.975, 30.665, -197.465]} rotation={[2.973, 1.198, -2.997]} scale={[0.993, 1.373, 0.993]} />
      <mesh geometry={nodes.Icosphere062.geometry} material={materials.treegreen2} position={[31.819, 55.722, -214.691]} scale={[8.435, 5.19, 8.435]} />
      <mesh geometry={nodes.Cube096.geometry} material={materials.treewood4} position={[31.143, 37.5, -216.349]} rotation={[0, 0.149, 0]} scale={[1.218, 1.685, 1.218]} />
      <mesh geometry={nodes.SDFSDF.geometry} material={materials.SDFSDF} position={[-229.988, 178.396, 148.901]} rotation={[Math.PI / 2, 0, Math.PI]} />
      <mesh geometry={nodes.Plane.geometry} material={materials.ground} position={[0, 20.175, -119.221]} rotation={[0, 0, -Math.PI]} scale={194.607} />
      <mesh geometry={nodes.trees017.geometry} material={materials['minitree.001']} position={[-54.864, 39.803, -123.73]} rotation={[0, 0.336, 0]} scale={[1.31, 1.797, 1.31]} />
      <mesh geometry={nodes.trees018.geometry} material={materials['minitree.002']} position={[-80.31, 33.235, -64.932]} rotation={[-Math.PI, 1.099, -Math.PI]} scale={[0.872, 1.196, 0.872]} />
      <mesh geometry={nodes.trees009.geometry} material={materials.minitree2} position={[-68.246, 34.004, -33.772]} rotation={[0, -0.007, 0]} scale={[1.132, 1.356, 1.112]} />
      <mesh geometry={nodes.trees011.geometry} material={materials['minitree.004']} position={[12.112, 28.571, -32.384]} rotation={[0, 1.539, 0]} scale={[0.537, 0.736, 0.537]} />
      <mesh geometry={nodes.trees008.geometry} material={materials.minitree2} position={[37.4, 29.661, -34.696]} rotation={[0, 0.336, 0]} scale={[0.753, 1.032, 0.753]} />
      <mesh geometry={nodes.trees007.geometry} material={materials['minitree2.001']} position={[79.352, 26.424, 0.319]} rotation={[-3.121, 0.238, 3.056]} scale={[0.452, 0.62, 0.452]} />
      <mesh geometry={nodes.trees004.geometry} material={materials['minitree.006']} position={[71.216, 28.571, -27.437]} rotation={[0, 1.539, 0]} scale={[0.537, 0.736, 0.537]} />
      <mesh geometry={nodes.trees003.geometry} material={materials['minitree.007']} position={[95.304, 25.455, -22.776]} rotation={[0, 1.539, 0]} scale={[0.401, 0.457, 0.537]} />
      <mesh geometry={nodes.trees005.geometry} material={materials['minitree.008']} position={[107.448, 26.893, -9.144]} rotation={[0, 1.539, 0]} scale={[0.665, 0.757, 0.804]} />
      <mesh geometry={nodes.trees002.geometry} material={materials['minitree.009']} position={[34.973, 27.224, -0.21]} rotation={[-Math.PI, 1.424, -Math.PI]} scale={[0.487, 0.668, 0.487]} />
      <mesh geometry={nodes.sign001.geometry} material={materials['Material.008']} position={[-27.418, 27.693, -219.775]} rotation={[Math.PI, -0.654, Math.PI]} scale={[1.257, 1.757, 1.757]} />
      <group position={[60.288, 43.276, 10.435]} rotation={[-Math.PI, 1.531, -Math.PI]}>
        <mesh geometry={nodes.Cube008_1.geometry} material={materials.HOUSE1} />
        <mesh geometry={nodes.Cube008_2.geometry} material={materials['HOUSE1.001']} />
        <mesh geometry={nodes.Cube008_3.geometry} material={materials['HOUSE1.002']} />
      </group>
      <mesh geometry={nodes.Cube023.geometry} material={nodes.Cube023.material} position={[37.735, 24.922, 4.035]} rotation={[-Math.PI, 0.013, -Math.PI]} />
      <mesh geometry={nodes.Cube004.geometry} material={materials.HOUSE1} position={[60.288, 43.276, 10.435]} rotation={[-Math.PI, 1.531, -Math.PI]} />
      <mesh geometry={nodes.Cube012.geometry} material={materials.HOUSE1} position={[60.288, 43.276, 10.435]} rotation={[-Math.PI, 1.531, -Math.PI]} />
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.079']} position={[-2.401, 21.61, -4.256]} rotation={[0, 0.717, 0]} scale={[0.921, 1.899, 1.166]} />
      <mesh geometry={nodes.Icosphere.geometry} material={materials.treegreen1} position={[-2.401, 21.61, -4.256]} rotation={[0, 0.717, 0]} scale={[4.065, 2.427, 5.943]} />
      <mesh geometry={nodes.Icosphere001.geometry} material={materials.treegreen1} position={[-2.681, 21.61, -1.996]} rotation={[0, 0.717, 0]} scale={[4.479, 4.562, 5.293]} />
      <mesh geometry={nodes.Icosphere002.geometry} material={materials.treegreen1} position={[-2.401, 21.61, -4.256]} rotation={[0, 0.647, 0]} scale={[1.707, 1.543, 3.152]} />
      <mesh geometry={nodes.Cube075.geometry} material={materials.treewood3} position={[14.483, 20.77, 0.569]} scale={1.385} />
      <mesh geometry={nodes.Icosphere006.geometry} material={materials.treegreen2} position={[14.483, 33.927, 0.569]} scale={[7.4, 6.57, 7.4]} />
      <mesh geometry={nodes.Icosphere007.geometry} material={materials.treegreen2} position={[12.127, 37.394, 0.569]} rotation={[-0.057, -0.007, 0.354]} scale={[6.336, 5.626, 6.336]} />
      <mesh geometry={nodes.Icosphere008.geometry} material={materials.treegreen2} position={[17.443, 36.691, -1.485]} rotation={[-0.032, 0.574, -0.085]} scale={[4.666, 4.143, 4.666]} />
      <mesh geometry={nodes.Cube074.geometry} material={materials.treewood3} position={[3.128, 31.448, 9.426]} scale={[0.972, 1.345, 0.972]} />
      <mesh geometry={nodes.Icosphere003.geometry} material={materials.treeorange1} position={[1.898, 47.372, 9.333]} rotation={[0, 0.454, 0]} scale={[8.601, 6.544, 12.575]} />
      <mesh geometry={nodes.Icosphere004.geometry} material={materials.treeorange1} position={[1.898, 52.961, 9.333]} rotation={[0, 0.454, 0]} scale={[6.939, 3.701, 10.145]} />
      <mesh geometry={nodes.Icosphere005.geometry} material={materials.treeorange1} position={[0.933, 55.154, 11.005]} rotation={[0, 0.454, 0]} scale={[4.816, 3.701, 7.041]} />
      <mesh geometry={nodes.Cube027.geometry} material={materials.house4} position={[-86.613, 27.113, -88.145]} rotation={[Math.PI, -0.425, Math.PI]} />
      <mesh geometry={nodes.Cube001.geometry} material={materials['Material.039']} position={[-86.613, 27.113, -88.145]} rotation={[Math.PI, -0.425, Math.PI]} />
      <mesh geometry={nodes.Cube002.geometry} material={materials['Material.087']} position={[-86.613, 27.949, -88.145]} rotation={[Math.PI, -0.425, Math.PI]} />
      <mesh geometry={nodes.Cube003.geometry} material={materials['Material.038']} position={[-86.613, 27.113, -88.145]} rotation={[Math.PI, -0.425, Math.PI]} />
      <mesh geometry={nodes.Cube008.geometry} material={materials.Material} position={[-86.613, 27.113, -88.145]} rotation={[Math.PI, -0.425, Math.PI]} />
      <mesh geometry={nodes.Cube009.geometry} material={materials['Material.039']} position={[-52.912, 31.906, -25.063]} rotation={[Math.PI, -0.425, Math.PI]} />
      <group position={[-96.134, 35.89, -91.689]} scale={[1.08, 1.481, 1.08]}>
        <mesh geometry={nodes.Sphere002.geometry} material={materials['tree3.017']} />
        <mesh geometry={nodes.Sphere002_1.geometry} material={materials['wood1.023']} />
      </group>
      <mesh geometry={nodes.Cube005.geometry} material={materials['HOUSE1.003']} position={[-43.265, 25.627, -24.517]} rotation={[0.005, 0.838, 0]} />
      <mesh geometry={nodes.Cube006.geometry} material={materials['HOUSE1.006']} position={[-88.646, 26.148, -130.615]} rotation={[0.003, 0.145, 0.003]} />
      <mesh geometry={nodes.Cube010.geometry} material={materials['Material.043']} position={[-16.779, 31.906, -241.822]} rotation={[0, -0.339, 0]} scale={[1.093, 1.493, 1.426]} />
      <mesh geometry={nodes.Cube011.geometry} material={materials['Material.044']} position={[-13.533, 31.95, -234.349]} rotation={[-0.05, 1.217, 0.001]} scale={[1.093, 1.493, 1.426]} />
      <mesh geometry={nodes.Cube013.geometry} material={materials['HOUSE1.009']} position={[-12.09, 43.568, -242.854]} rotation={[0, -1.531, 0]} scale={[1.002, 1, 1.641]} />
    </group>
  )
}

useGLTF.preload('/models/main_ground.gltf')
