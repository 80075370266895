/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 grandmotherRoom.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function GMHome(props) {
  const { nodes, materials } = useGLTF('/models/grandmotherRoom.gltf')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube008.geometry} material={materials.wall} position={[-7.522, 2.948, 17.757]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={[-9.431, -0.174, -17.177]} />
      <mesh geometry={nodes.Cube010.geometry} material={materials.drawer} position={[-4.84, 2.127, -9.624]} scale={[1.852, 1.821, 1.915]} />
      <mesh geometry={nodes.Cube013.geometry} material={materials.closet} position={[0.2, 7.176, 10.161]} rotation={[0, 0, Math.PI]} scale={[4.473, 8.236, 1.588]} />
      <mesh geometry={nodes.Cube037.geometry} material={materials['Material.002']} position={[-1.409, 1.204, -3.709]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.316, 0.869, 0.316]} />
      <mesh geometry={nodes.Cube040.geometry} material={materials['Material.009']} position={[0.604, 2.332, -2.551]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.761, 0.318, 1.729]} />
      <group position={[-1.895, 9.613, -11.621]}>
        <mesh geometry={nodes.Cube027.geometry} material={materials['Material.015']} />
        <mesh geometry={nodes.Cube027_1.geometry} material={materials.frame1} />
      </group>
      <mesh geometry={nodes.Cube044.geometry} material={materials.sofa} position={[1.707, 3.042, -10.536]} rotation={[Math.PI / 2, 0, Math.PI]} scale={0.844} />
      <mesh geometry={nodes.Cube049.geometry} material={materials.sofa} position={[7.265, 1.32, -3.498]} scale={[1.348, 2.954, 1.348]} />
      <mesh geometry={nodes.Cube062.geometry} material={materials.desk} position={[9.219, 8.673, 10.161]} scale={[4.473, 8.315, 1.588]} />
      <mesh geometry={nodes.Cube072.geometry} material={materials['Material.017']} position={[13.308, 5.659, -8.911]} rotation={[0, 0.259, 0]} scale={[1.751, 2.397, 1.751]} />
      <mesh geometry={nodes.Cube086.geometry} material={materials.book1} position={[1.249, 3.413, -2.63]} rotation={[Math.PI, -1.366, Math.PI]} scale={[1, 0.866, 0.896]} />
      <mesh geometry={nodes.Cube087.geometry} material={materials.lamp2} position={[-4.84, 3.141, -9.67]} scale={[0.541, 0.32, 0.559]} />
      <mesh geometry={nodes.Cube093.geometry} material={nodes.Cube093.material} position={[-4.84, 3.687, -9.7]} scale={[0.226, 0.656, 0.234]} />
      <mesh geometry={nodes.Cube098.geometry} material={materials.lamp} position={[-4.84, 4.292, -9.642]} scale={[0.68, 0.656, 0.704]} />
      <mesh geometry={nodes.Cube101.geometry} material={materials.deskbook} position={[8.396, 12.622, 10.51]} scale={[1, 0.937, 0.513]} />
      <mesh geometry={nodes.Cube102.geometry} material={materials.Material} position={[8.977, 4.78, 8.776]} scale={[1.314, 1, 1]} />
      <mesh geometry={nodes.Cube121.geometry} material={materials['Material.011']} position={[-1.704, 0.144, 0.247]} scale={[19.828, 0.242, 12.167]} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material.018']} position={[10.888, 1.446, -10.238]} scale={[0.955, 4.232, 0.955]} />
      <mesh geometry={nodes.Plane007.geometry} material={materials.curtain} position={[13.84, 8.643, -8.258]} rotation={[0, 0, -Math.PI / 2]} scale={[5.715, 1, 1.536]} />
      <mesh geometry={nodes.Plane008.geometry} material={materials.curtain} position={[13.84, 8.643, -1.92]} rotation={[0, 0, -Math.PI / 2]} scale={[5.715, 1, 1.536]} />
      <mesh geometry={nodes.Plane009.geometry} material={materials.curtain} position={[13.84, 8.643, 7.848]} rotation={[0, 0, -Math.PI / 2]} scale={[5.715, 1, 1.536]} />
      <mesh geometry={nodes.Plane010.geometry} material={materials.curtain} position={[13.84, 8.643, 1.51]} rotation={[0, 0, -Math.PI / 2]} scale={[5.715, 1, 1.536]} />
      <mesh geometry={nodes.Cube035.geometry} material={materials['book1.001']} position={[1.249, 2.951, -2.63]} rotation={[-Math.PI, 1.486, -Math.PI]} scale={[1, 0.866, 0.896]} />
      <mesh geometry={nodes.Cube031.geometry} material={materials.window} position={[14.524, 9.289, 5.211]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[-5.379, -0.308, -3.709]} />
      <mesh geometry={nodes.Cube042.geometry} material={materials.window} position={[14.524, 9.289, -5.35]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[-5.379, -0.308, -3.709]} />
      <mesh geometry={nodes.Plane015.geometry} material={materials['Material.020']} position={[16.131, 9.801, 0.77]} rotation={[0, 0, -Math.PI / 2]} scale={[5.919, 1, 9.509]} />
    </group>
  )
}

useGLTF.preload('/models/grandmotherRoom.gltf')
